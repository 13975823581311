import React from 'react';
import styled from 'styled-components';

const Site404Wrapper = styled.div`
  padding: 24px;
  .emoji-404 {
    font-size: 100px;
  }
  .title-404 {
    font-size: 18px;
    font-weight: 600;
  }
  .code-404 {
    color: #737272;
    font-size: 12px;
    position: absolute;
    bottom: 10px;
  }
`;

const Site404 = () => {
  return (
    <Site404Wrapper>
      <span role="img" aria-label="emoji" className="emoji-404">
        &#128557;
      </span>
      <p className="title-404">Uh oh, trang này lạ quá</p>
      <p className="description-404">
        Trang bạn đang cố truy cập hiện không thể tìm thấy, bạn hãy kiểm tra lại đường dẫn nhé!
      </p>
      <p className="code-404">HTML Error 404</p>
    </Site404Wrapper>
  );
};

Site404.propTypes = {};

export default Site404;
